@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
body {
  @apply leading-[normal] m-0 antialiased;
}
*,
::before,
::after {
  border-width: 0;
}
.resume-builder-preview * {
  @apply m-0;
}
@layer components {
  .text-editor {
    @apply bg-base-white flex overflow-hidden flex-col w-full h-52 transition-all duration-300 border border-solid border-neutral-700 rounded-md;
  }
  .text-editor .ql-container {
    @apply !border-none;
  }
  .text-editor :is(.ql-toolbar, .ql-container) {
    @apply border-none border-0;
  }
  .text-editor .ql-toolbar {
    @apply !border-none bg-primary/10 flex;
  }
  .text-editor .ql-toolbar .ql-formats {
    @apply !mr-3 pr-3 border-r-0 relative flex items-center;
  }
  .text-editor .ql-toolbar .ql-formats:not(:last-child):after {
    content: "";
    @apply w-[1px] h-4 bg-gray-300 absolute right-0;
  }
  .text-editor .ql-toolbar .ql-formats button {
    @apply !w-6 !h-5;
  }
  .text-editor .ql-editor {
    @apply font-text-l-regular text-sm text-black !px-4 !py-2;
  }
}

.disabledrag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.swiper-button-next,
.swiper-button-prev {
  color: #ffffff !important;
  border: 1px solid #ffffff;
}

/* Customize the color of the arrows inside the buttons */
.swiper-button-next::after,
.swiper-button-prev::after {
  color: #ffffff; /* Replace with the color code for the arrow */
}
