@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

@font-face {
  font-family: "IBMPlexSans-Medium";
  src: url("IBMPlexSans-Medium.woff2") format("woff2"),
    url("IBMPlexSans-Medium.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBMPlexSans-Bold";
  src: url("IBMPlexSans-Bold.woff2") format("woff2"),
    url("IBMPlexSans-Bold.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("IBMPlexSans-Regular.woff2") format("woff2"),
    url("IBMPlexSans-Regular.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

.swiper.swiper-initialized.swiper-horizontal {
  width: calc(100% - 8rem);
  overflow: unset;
}

.swiper-button-next,
.swiper-button-prev {
  @apply !text-white !bg-gray-600 !h-10 !w-10 !rounded-full hover:bg-gray-600/60;
  -webkit-box-shadow: 5px 5px 10px 2px rgba(133, 138, 143, 0.73);
  -moz-box-shadow: 5px 5px 10px 2px rgba(133, 138, 143, 0.73);
  box-shadow: 5px 5px 10px 2px rgba(133, 138, 143, 0.73);
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1rem !important;
}

.swiper-button-prev {
  left: -3.4rem;
}

.swiper-button-next {
  right: -3.4rem;
}

.swiper-pagination-bullet {
  @apply hidden;
}
